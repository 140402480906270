<template>
  <div>
    <!-- 居民补户验收查询 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          <el-form>
          工程编号/名称:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input>
          <span style="margin-right:20px"></span>
          安装施工队:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          <span style="margin-right:20px"></span>
          现场管理员:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
          ></el-input>
          <span style="margin-right:20px"></span>
          验收人:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.acceptUser"
          ></el-input>
          
          <!-- 试验结果:
          <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>

          </el-select> -->
          <span style="margin-right:20px"></span>
          提交时间:
            <el-date-picker
              size="small"
              v-model="searchBox.time"
              type="datetimerange"
              style="width: 200px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择验收时间"
            >
            </el-date-picker>
            <span style="margin-right:20px"></span>
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="resetData"
            >重置</el-button
          >
          </el-form>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
             height="calc(100vh - 350px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
            @sort-change="sort_change"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="验收结果" width="80px"
              align="center"
              show-overflow-tooltip
            >
            <!-- 写死的合格 -->
              <template>
             <el-tag type="success"> 合格</el-tag>
              </template>
            </el-table-column>
            <el-table-column  width="80px"
              label="验收照片"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div style="cursor: pointer" @click="pictureDetil(row)">
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="explain"
              label="验收说明"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="gradeNumber"
              label="表编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="type"
              label="燃气表类型"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
             <el-table-column
              prop="directionName"
              label="表向"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="venderName"
              label="燃气表厂家"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column width="150px"
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
            ></el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[ 30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="验收图片"
      :visible.sync="pictureDialog"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-image
          style="width: 100px; height: 100px"
          :src="project[0]"
          :preview-src-list="project"
      >
      </el-image>
      <span style="margin-left: 10px">
        共{{ project.length }}张
      </span>
    </el-dialog>
  </div>
</template>

<script>

import  {getResident} from '../../RequestPort/intranet/index'
export default {
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: "1",
      project: [],
      searchBox: {
        current: 1,
        size: 100,
        name: "",
        result: [],
        searchText:'',
        install:'',
        time:[]
      },
      total: 0,
      detilDialog: false,
      pictureDialog: false,
      condition:true,
      typeList: [
        {
          label: "合格",
          value: 0,
        },
        {
          label: "不合格",
          value: 1,
        },
        {
          label: "未出结果",
          value: 2,
        },
      ],
      tableData: [{}],
    };
  },
  mounted() {
    this.loadList(this.searchBox);
  },
  methods: {
    sort_change(column){
      if(column.order=='ascending'){
        this.searchBox.sortType = 'asc';
      }else{
        this.searchBox.sortType = 'desc';
      }
      this.loadList(this.searchBox);
    },
    pictureDetil(row) {
      this.project =[]
      this.pictureDialog = !this.pictureDialog;
      if(row.img){
        this.project = row.img.split(',')
      }
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    loadList(obj) {
      if(obj.time&&obj.time.length>0){
        obj.startDate = obj.time[0]
        obj.endDate = obj.time[1]
      }else{
         obj.startDate = ''
         obj.endDate = ''
      }
      let dataObj = {...obj};
      dataObj.time = ''
      getResident(dataObj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    resetData() {
      this.searchBox={
        current: 1,
        size: 100,
        name: "",
        result: [],
        searchText:'',
        install:'',
        time:[]
      }
      this.loadList(this.searchBox)
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
/* .SearchBtn { */
  /* margin-left: 10px; */
/* } */
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>